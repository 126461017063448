import { APP_ICONS } from "Assets/icons";
import { Link } from "Components/link";
import { PATHS } from "constants/routes";

export default function FreshdiLogo() {
  return (
    <Link to={PATHS.home}>
      <img className="logo" src={APP_ICONS.logo} alt="logo" height={48} />
    </Link>
  );
}
