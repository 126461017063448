import { ButtonProps, Dialog, IconButton, styled } from "@mui/material";
import { ChatIcon, LockIcon, SmCircleChatIcon } from "Assets/icons";
import { ButtonBase } from "Components/Button.styles";
import { BlockChatPopup } from "Components/popup";
// import Tooltip from "Components/tooltip/Tooltip";
import { COLORS } from "constants/layout";
import useAuth from "hooks/useAuth";
import useChat from "hooks/useChat";
import useUser from "hooks/useUser";
import { MouseEvent, useMemo, useState } from "react";
import { useIntl } from "react-intl";

const iconProps = {
  stroke: COLORS.green[600],
  width: 18,
  height: 18,
};

const SendMessageButton = ({
  identity,
  shorter,
  inBiz,
  ...rest
}: { identity?: string; shorter?: boolean; inBiz?: boolean } & ButtonProps) => {
  const { user, isLoggedIn } = useUser();
  const { onDirectLogin } = useAuth();
  const { onOpenConversationWithIdentity } = useChat();
  const t = useIntl();

  const [showDialog, setShowDialog] = useState(false);

  const isVerifiedBuyer = useMemo(
    () => user?.plan?.is_biz_verified === 1 || inBiz,
    [user?.plan, inBiz]
  );

  const onClickSendMsg = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (isLoggedIn) {
      if (isVerifiedBuyer) {
        onOpenConversationWithIdentity(identity);
      } else {
        onOpenDialog();
      }
    } else {
      onDirectLogin();
    }
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onOpenDialog = () => setShowDialog(true);

  // <Tooltip title={t.formatMessage({ id: "send_inquiry_to_start_chat" })}>
  return (
    <>
      {shorter ? (
        <IconButton className="chat-icon fit" onClick={onClickSendMsg}>
          <SmCircleChatIcon stroke={COLORS.green[600]} />
        </IconButton>
      ) : (
        <StyledButton
          onClick={onClickSendMsg}
          endIcon={
            isVerifiedBuyer ? (
              <ChatIcon {...iconProps} />
            ) : (
              <LockIcon {...iconProps} />
            )
          }
          {...rest}
        >
          {t.formatMessage({ id: "chat_now" })}
        </StyledButton>
      )}

      <Dialog
        open={showDialog}
        onClose={onCloseDialog}
        PaperProps={{ sx: { width: "100%", maxWidth: 504 } }}
      >
        <BlockChatPopup onClose={onCloseDialog} />
      </Dialog>
    </>
  );
};

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${COLORS.green[600]}`,
  color: COLORS.green[600],

  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    ".MuiButton-icon svg": {
      maxHeight: 21,
    },
  },
}));

export default SendMessageButton;
