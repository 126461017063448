import { MAX_NOTI_CHAR_QLT } from "constants/layout";

export const formatCurrency = (text: string | number) => {
  return String(text)
    ?.toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatCurrencyWithUnit = (text: string | number, unit = "$") => {
  return (
    unit +
    String(text)
      ?.toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

export const formatNumber = (text?: string | number) => {
  if (text == null || text === "") return "";
  return String(text)
    ?.toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const limitCountNoti = (count: number = 0) => {
  return Number(count) > 0
    ? `${Math.min(count || 0, MAX_NOTI_CHAR_QLT)}${
        count > MAX_NOTI_CHAR_QLT ? "+" : ""
      }`
    : null;
};

export const removeHTMLTags = (str: string = "") => {
  return (str || "").replace(/<\/?[^>]+(>|$)/g, "");
};

export const getDataFromUniqueNameChat = (
  uniqueName: string | null | undefined,
  field: "buyerIdentity" | "supplierIdentity"
): string | undefined | null => {
  try {
    if (uniqueName) {
      const [buyerIdentity, supplierIdentity] = uniqueName.split("|");
      return field === "buyerIdentity" ? buyerIdentity : supplierIdentity;
    }
    return undefined;
  } catch (error) {
    return uniqueName;
  }
};

export const isNotNull = (variable: any) => {
  return variable !== null && variable !== undefined;
};

export const checkEmptyObject = (obj: object) => {
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  } else {
    return false;
  }
};

export const decodeGoogleIdToken = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Error decoding ID token:", error);
    return null;
  }
};

export const extractLink = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  const match = text.match(urlRegex);
  return match ? match[0] : null;
};

export const extractLinks = (text: string) => {
  const urlRegex = /\[.*?\]\((https?:\/\/[^\s)]+)\)/g;
  const matches = [...text.matchAll(urlRegex)];
  return matches.map((match) => match[1]);
};
