import { SVGProps } from "react";

export function EmailIcon({
  width = 21,
  height = 21,
  viewBox = "0 0 21 21",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M18.8333 5.22624C18.8333 4.30957 18.0833 3.55957 17.1667 3.55957H3.83332C2.91666 3.55957 2.16666 4.30957 2.16666 5.22624M18.8333 5.22624V15.2262C18.8333 16.1429 18.0833 16.8929 17.1667 16.8929H3.83332C2.91666 16.8929 2.16666 16.1429 2.16666 15.2262V5.22624M18.8333 5.22624L10.5 11.0596L2.16666 5.22624"
        stroke={color}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Email2Icon({
  width = 20,
  height = 20,
  viewBox = "0 0 20 20",
  color = "#667085",
  strokeWidth = 1,
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns={xmlns}
      {...props}
    >
      <path
        d="M18.3334 5.00016C18.3334 4.0835 17.5834 3.3335 16.6667 3.3335H3.33341C2.41675 3.3335 1.66675 4.0835 1.66675 5.00016M18.3334 5.00016V15.0002C18.3334 15.9168 17.5834 16.6668 16.6667 16.6668H3.33341C2.41675 16.6668 1.66675 15.9168 1.66675 15.0002V5.00016M18.3334 5.00016L10.0001 10.8335L1.66675 5.00016"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
