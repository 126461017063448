import { ReactNode, useEffect } from "react";
import { LoadingProvider } from "./loading";
import NotifyProvider from "./notify";
import { CommonDataProvider } from "./common-data";
import { LayoutProvider } from "./layout";
import NotificationHandler from "helpers/notification";
import ScrollToTop from "Components/ScrollToTop";
import ChatProvider from "./chat";
import { FloatingActionBar } from "Components/bar";
import { SEARCH_PARAMS } from "constants/routes";
import { getCookie, setCookie } from "helpers/cookie";

const AppProvider = ({ children }: { children: ReactNode }) => {
  const getUtmSource = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get(SEARCH_PARAMS.utmSource);
    const localUtmSource = getCookie(SEARCH_PARAMS.utmSource);
    if (!localUtmSource) {
      setCookie(SEARCH_PARAMS.utmSource, utmSource || "origin", 30);
    }
  };

  useEffect(() => {
    getUtmSource();
  }, []);

  return (
    <CommonDataProvider>
      <ChatProvider>
        <LayoutProvider>
          <LoadingProvider>
            <NotifyProvider>
              {children}

              {/* Scroll top */}
              <ScrollToTop />

              {/* Float action bar */}
              <FloatingActionBar />
              <NotificationHandler />
            </NotifyProvider>
          </LoadingProvider>
        </LayoutProvider>
      </ChatProvider>
    </CommonDataProvider>
  );
};

export default AppProvider;
