import useAuth from "hooks/useAuth";
import useUser from "hooks/useUser";
import React, { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";

type Props = {
  children?: ReactNode;
};
const AILayout = ({ children }: Props) => {
  const userStatus = useSelector(
    (state: any) => state?.userReducer?.userStatus
  );
  const { onDirectLogin } = useAuth();
  const { isLoggedIn } = useUser();

  useEffect(() => {
    if (!isLoggedIn && userStatus === "fulfilled") {
      onDirectLogin();
    }
  }, [isLoggedIn, onDirectLogin, userStatus]);

  return <>{children}</>;
};

export default AILayout;
