import { Redirect, Route, Switch } from "react-router-dom";

import { withProfile } from "hocs/withProfile";
import {
  Category,
  Login,
  QuickRFQ,
  ResetPassword,
  Sitemap,
  Register,
  SendInquiry,
  ActiveAccount,
  Products,
  Suppliers,
  Payment,
  ActiveAccountBuyer,
  Unsubscribe,
  Farm,
  FarmItem,
  JoinCampaign,
  NewArrivals,
  FreshdiChoice,
  Home,
  PageNotFound,
  PaymentPending,
  Pricing,
  ContactUs,
  TermOfUse,
  PrivacyPolicy,
  IntellectualPropertyPolicy,
  Disclaimers,
  TrustStar,
  DashBoard,
  Messages,
  RequestQuotation,
  ReceivedQuotation,
  ReceivedQuotationDetail,
  Inquiries,
  Profile,
  Membership,
  Analytics,
  AiAgent,
} from "Pages";
import { Layout } from "Components";
import { PATHS } from "constants/routes";
import SetNewPassword from "Pages/reset-password/components/SetNewPassword";
import AuthLayout from "layouts/auth-layout";
import HelperLayout from "layouts/helper-layout";
import ChatToggleBar from "Components/chat/ChatToggleBar";
import { useSelector } from "react-redux";
import { getUser } from "redux/selectors/user";
import UserLayout from "Components/layout/UserLayout";
import AILayout from "Components/layout/AILayout";

const AppRouter = () => {
  const user = useSelector(getUser) || {};

  const layoutRoutes = [
    { path: "/", exact: true, component: Home },
    { path: "/quick-rfq", component: QuickRFQ },
    { path: `${PATHS.supplier}/:supplierId`, component: Farm },
    { path: `${PATHS.item}/:itemId`, component: FarmItem },
    { path: [PATHS.search, PATHS.searchSuppliers], component: Category },
    {
      path: [
        `${PATHS.category}/:countryId/:categoryId`,
        `${PATHS.categorySupplier}/:countryId/:categoryId`,
      ],
      component: Category,
    },
    {
      path: [
        `${PATHS.category}/:categoryId`,
        `${PATHS.categorySupplier}/:categoryId`,
      ],
      component: Category,
    },
    { path: PATHS.sendInquiry, component: SendInquiry },
    { path: PATHS.activeAccount, component: ActiveAccount },
    { path: PATHS.products, component: Products },
    { path: PATHS.suppliers, component: Suppliers },
    { path: PATHS.activeAccountBuyer, component: ActiveAccountBuyer },
    { path: PATHS.unsubscribe, component: Unsubscribe },
    { path: PATHS.joinCampaign, component: JoinCampaign },
    { path: `${PATHS.newArrivals}/:categoryId?`, component: NewArrivals },
    { path: `${PATHS.freshdiChoice}/:categoryId?`, component: FreshdiChoice },
    { path: `${PATHS.paymentPending}/:token`, component: PaymentPending },
    { path: PATHS.pricing, component: Pricing },
    { path: PATHS.contactUs, component: ContactUs },
    { path: PATHS.trustStar, component: TrustStar },
  ];

  const helpersRoutes = [
    { path: PATHS.sitemap, component: Sitemap },
    { path: PATHS.termCondition, component: TermOfUse },
    { path: PATHS.policy, component: PrivacyPolicy },
    {
      path: PATHS.intellectualPropertyPolicy,
      component: IntellectualPropertyPolicy,
    },
    { path: PATHS.disclaimers, component: Disclaimers },
  ];

  const userRoutes = [
    { path: PATHS.overview, component: DashBoard },
    { path: PATHS.messages, component: Messages },
    { path: PATHS.requestQuotation, component: RequestQuotation },
    {
      path: `${PATHS.receivedQuotation}/:quotationId`,
      component: ReceivedQuotationDetail,
    },
    { path: PATHS.receivedQuotation, component: ReceivedQuotation },
    { path: PATHS.inquiries, component: Inquiries },
    { path: PATHS.profile, component: Profile },
    { path: PATHS.membership, component: Membership },
    { path: PATHS.analytics, component: Analytics },
  ];

  return (
    <Switch>
      <Route path={PATHS.paymentBuyer} component={Payment} />
      <Route path={PATHS.paymentSupplier} component={Payment} />
      <Route path={PATHS.user}>
        <UserLayout>
          <Switch>
            {userRoutes.map((route, idx) => (
              <Route key={idx} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </UserLayout>
      </Route>

      {/* Auth layout */}
      <Route path={PATHS.register}>
        <AuthLayout hideBanner children={<Register />} />
      </Route>
      <Route path={PATHS.login} exact>
        <AuthLayout children={<Login />} />
      </Route>
      <Route path={PATHS.resetPassword}>
        <AuthLayout children={<ResetPassword />} />
      </Route>
      <Route path={PATHS.updatePassword}>
        <AuthLayout children={<SetNewPassword />} />
      </Route>

      <Route path={PATHS.aiAgent}>
        <AILayout>
          <AiAgent />
        </AILayout>
      </Route>

      <Route path="/">
        <Layout>
          <Switch>
            {/* Helper Routes */}
            {helpersRoutes.map((route, idx) => (
              <Route key={idx} path={route.path} exact={route.exact}>
                <HelperLayout children={<route.component />} />
              </Route>
            ))}

            {/* App Routes */}
            {layoutRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            <Route path={PATHS.notFound} component={PageNotFound} />
            <Redirect to={PATHS.notFound} />
          </Switch>

          {/* Chat toggle bar */}
          {user.is_logged_in && <ChatToggleBar />}
        </Layout>
      </Route>

      <Route path="*" component={() => <Redirect to={PATHS.notFound} />} />
    </Switch>
  );
};

export default withProfile(AppRouter);
